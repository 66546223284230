<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">Profile</h1>

    <div class="row">
      <div class="col col--9">
        <div class="box box--white box--lg mt-16">
          <div class="tile tile--profile">
            <div class="tile__image">
              <toko-profile-icon />
            </div>
            <div class="tile__content">
              <div class="statistic mb-24">
                <p class="statistic__title text-warm">Full Name</p>
                <div v-if="isEditFormMopen" class="form-group">
                  <input type="text" class="form-control" v-model="$v.params.fullName.$model" />
                  <p v-if="$v.params.fullName.$anyError" class="form-error-text">
                    <span v-if="!$v.params.fullName.required">fullName can't be blank</span>
                  </p>
                </div>
                <h2 v-else class="headline--md mt-4">{{ currentUser.fullName }}</h2>
              </div>

              <div class="statistic mb-24">
                <p class="statistic__title text-warm">Phone</p>
                <div v-if="isEditFormMopen" class="form-group">
                  <input type="text" class="form-control" v-model="params.phone" />
                </div>
                <h2 v-else class="headline--md mt-4">{{ currentUser.phone || 'Not available' }}</h2>
              </div>

              <div class="statistic mb-24">
                <p class="statistic__title text-warm">Country Code</p>
                <div v-if="isEditFormMopen" class="form-group">
                  <input type="text" class="form-control" v-model="params.countryCode" />
                </div>
                <h2 v-else class="headline--md mt-4">{{ currentUser.countryCode || 'Not available' }}</h2>
              </div>

              <div class="statistic mb-24">
                <p class="statistic__title text-warm">Email</p>
                <h2 class="headline--md mt-4">{{ currentUser.email }}</h2>
              </div>

              <div class="statistic mb-24">
                <p class="statistic__title text-warm">Role</p>
                <h2 class="headline--md mt-4">{{ currentUser.role | format_bo_user_role_text }}</h2>
              </div>

              <div class="mb-24">
                <button class="btn btn--clear text-purple text-extra-bold" @click="isChangePasswordModalOpen = true">
                  Change Password
                </button>
              </div>

              <div class="btn-group">
                <button v-if="isEditFormMopen" class="btn btn--purple-bordered" @click="onCancelEditProfile">
                  Cancel
                </button>

                <button class="btn btn--primary" @click="onEditProfile">Update Profile</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal modalClass="modal--md" :open.sync="isChangePasswordModalOpen">
      <change-password-form v-if="isChangePasswordModalOpen" @on-change-password="onChangePassword($event)" />
    </modal>

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import editProfileFormValidator from './validations/edit-profile-form'
import toggleLoading from '@/mixins/toggle-loading'
const TokoProfileIcon = () => import('@/ui/toko-icons/TokoProfile')
const Modal = () => import('@/components/Modal')
const ChangePasswordForm = () => import('./components/ChangePasswordForm')

export default {
  data() {
    return {
      isChangePasswordModalOpen: false,
      isEditFormMopen: false,
      params: {
        fullName: '',
        phone: '',
        countryCode: ''
      }
    }
  },

  mixins: [editProfileFormValidator, toggleLoading],

  components: {
    TokoProfileIcon,
    Modal,
    ChangePasswordForm
  },

  methods: {
    ...mapActions('auth', ['changePassword', 'updateUserProfile']),

    onChangePassword(data) {
      this.toggleLoading()
      this.changePassword(data).finally(() => {
        this.toggleLoading()
      })
    },

    onEditProfile() {
      if (this.isEditFormMopen) {
        this.$v.$touch()
        if (this.isFormValid) {
          this.toggleLoading()
          this.updateUserProfile(this.onGetUserProfileRequest())
            .then(() => {
              this.isEditFormMopen = false
            })
            .finally(() => {
              this.toggleLoading()
            })
        }
      } else {
        this.isEditFormMopen = true
      }
    },

    onCancelEditProfile() {
      this.isEditFormMopen = false
      this.onInitProfileParams()
    },

    onInitProfileParams() {
      Object.keys(this.params).forEach(key => {
        this.params[key] = this.currentUser[key]
      })
    },

    onGetUserProfileRequest() {
      return {
        CountryCode: this.params.countryCode,
        FullName: this.params.fullName,
        Phone: this.params.phone,
        Username: this.params.username
      }
    }
  },

  created() {
    this.onInitProfileParams()
    this.toggleLoading()
  }
}
</script>
